import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import {FcDocument, FcAbout} from 'react-icons/fc';
import {MdDashboard} from 'react-icons/md';

import {  FaGem, FaGithub, FaHeart } from 'react-icons/fa';
import sidebarBg from './assets/bg1.jpg';
import { Link } from 'react-router-dom';
const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '0 24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          Referral Board
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            icon={<MdDashboard />}
            // suffix={<span className="badge red">{intl.formatMessage({ id: 'new' })}</span>}
          >Dashboard
            <Link to="/referrals" />
          </MenuItem>
          <MenuItem icon={<FcAbout />}> About
            <Link to="/referrals/about" />
          </MenuItem>
          <MenuItem icon={<FcDocument />}> Dev Documentation
            <Link to="/referrals/documentation" /> 
          </MenuItem>

        </Menu>
        {/* <Menu iconShape="circle">
          <SubMenu
            suffix={<span className="badge yellow">3</span>}
            title={intl.formatMessage({ id: 'withSuffix' })}
            icon={<FaRegLaughWink />}
          >
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 1</MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 2</MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3</MenuItem>
          </SubMenu>
        </Menu> */}
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper">
          <a
            href="https://github.com/GreenEOSIO/Game-Referrals"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <FaGithub />
            <span> {'Smart Contract Code'}</span>
          </a>
        </div>
      </SidebarFooter>
      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper">
        <footer>
        <small>
          © 2020 made with <FaHeart style={{ color: 'red' }} /> by -{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/greeneosio">
            GreenEOSIO
          </a>
        </small>
      </footer>
      
        </div>
      </SidebarFooter>

    </ProSidebar>
  );
};

export default Aside;
