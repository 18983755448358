import React, {
  useState,
  useEffect
} from 'react';

import { useMediaQuery } from 'react-responsive';

import Reward from './dashboardComponents/Reward';
import GamesPlayed from './dashboardComponents/GamesPlayed';
import RefChains from './dashboardComponents/RefChains';
import MyRefChains from './dashboardComponents/MyRefChains';
import Chart from './dashboardComponents/Chart';
import {getTable} from './WaxHelper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useLocation
} from "react-router-dom";

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;
const contractName="gamereferral";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function Main(props) {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  const [reward,setReward]=useState("0 WAX");
  const [gamesPlayed,setGamessPlayed]=useState(0);
  const [refChains,setRefChains]=useState(["No Refers"]);
  const [myRefChain,setMyRefChain]=useState([{key:"No Refers",value:1}]);
  const [chart,setChart]=useState([]);
  
  const[totalReward, setTotalReward]=useState("0 WAX");
  const [totalPlays, setTotalPlays]=useState(0);
  const [gameNames, setGameNames]=useState([]);

  const forcontract=props.match.params.contract;
  const acc_name=props.match.params.name;

  // const acc_name="2juau.wam";
  // var forcontract=query.get("name");


  useEffect(() => {

    // getTable(contractName,contractName,"accountg",acc_name,acc_name).then(r=>{

    //   if(r.rows.length>0)
    //   {
    //       setTotalReward(r.rows[0].t_reward);
    //       setTotalPlays(r.rows[0].t_plays);
    //       setGameNames(r.rows[0].games);
    //   }
    // }).catch(e=>{
    //   alert(e);
    // })

    getTable(forcontract,contractName,"referacc",acc_name,acc_name).then(r=>{

      if(r.rows.length>0)
      {
        setReward(r.rows[0].r_reward);
        setGamessPlayed(r.rows[0].r_games);

        if(r.rows[0].refer_chain.length>0)
          setRefChains(r.rows[0].refer_chain);

        if(r.rows[0].me_chains.length>0)        
          setMyRefChain(r.rows[0].me_chains);
          
        setChart(r.rows[0].dates);

      }
    }).catch(e=>{
      alert(e);
    })

    return () => {
    }
  }, []);

return (

    <div style={{width:'100%'}}>
      {isDesktopOrLaptop &&
        <div style={styles.flex}>
            <div style={styles.leftCol}>
                <div style={styles.flex}>
                    <Reward value={reward} />
                    <Spacer />
                    <GamesPlayed value={gamesPlayed} />
                </div>
                <div style={styles.singleCol}>
                    <Chart dates={chart} />
                </div>
            </div>
            <div style={styles.rightCol}>
                <div style={styles.flex}>
                    <RefChains
                        rc={myRefChain}
                    />
                    <Spacer />
                    <MyRefChains myChain={refChains} />
                </div>
            </div>
 
        </div>}

{!isDesktopOrLaptop &&
        <div style={styles.flexColumn}>
                <div style={styles.flex}>
                    <Reward value={reward} />
                    <Spacer />
                    <GamesPlayed value={gamesPlayed} />
                </div>
                    <Chart dates={chart} />
                    <RefChains
                        rc={myRefChain}
                    />
                    <Spacer />
                    <MyRefChains myChain={refChains} />
 
        </div>}
        
    </div>
);
};


export default Main

