import * as React from 'react';
import { useMemo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import { Link } from 'react-router-dom';

import CardWithIcon from './CardWithIcon';
// import { Customer } from '../types';

const MyRefChain = ({myChain}) => {

// console.log(myChain);

    // const nb = visitors ? visitors.reduce((nb: number) => ++nb, 0) : 0;
    return (
        <CardWithIcon
            // to=""
            icon={AccountTreeIcon}
            title={'Your Referral Chain'}
            subtitle={myChain.length}
        >
            <List>
                {myChain
                    ? myChain.map((record,index) => (
                          <ListItem
                              button
                            //   to={``}
                              component={Link}
                              key={record.key}
                          >
                              <ListItemAvatar>
                                  <Avatar style={{background:'#b20000'}} >{index+1}</Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                  primary={record}
                                //   {`${record} ${
                                //       record.last_name
                                //   }`}
                              />
                          </ListItem>
                      ))
                    : null}
            </List>
        </CardWithIcon>
    );
};

export default React.memo(MyRefChain);
