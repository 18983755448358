import * as React from 'react';
import { FC } from 'react';
import DollarIcon from '@material-ui/icons/Payment';

import CardWithIcon from './CardWithIcon';


const MonthlyRevenue = ({ value }) => {
    return (
        <CardWithIcon
            // to=""
            icon={DollarIcon}
            title={'Referral Reward'}
            subtitle={value}
        />
    );
};

export default MonthlyRevenue;
