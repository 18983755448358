import React, { useState } from 'react';
import Layout from './Layout';
import messages from './messages';
import './styles/App.scss';

function App() {

  return (
      <Layout/>

  );
}

export default App;