import React, {
    useState,
    useEffect
  } from 'react';
  
import Reward from './dashboardComponents/Reward';
import GamesPlayed from './dashboardComponents/GamesPlayed';
import RefGames from './dashboardComponents/RefGames';
import MyRefChains from './dashboardComponents/MyRefChains';
import {getTable} from './WaxHelper';
import { useMediaQuery } from 'react-responsive';


import { makeStyles } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const referContract="gamereferral";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));


  const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
  };
  
  const Spacer = () => <span style={{ width: '1em' }} />;
  const VerticalSpacer = () => <span style={{ height: '1em' }} />;
  // const contractName="gamereferral";
  
  function Account(props) {
  
    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-device-width: 1224px)'
    })
  

    const[totalReward, setTotalReward]=useState("0 WAX");
    const [totalPlays, setTotalPlays]=useState(0);
    const [gameNames, setGameNames]=useState([]);
    const [contractNames, setContractNames]=useState([]);
  
    // const forcontract="battlsofhumn";
    // // const acc_name="f..qw.wam	";
    // const acc_name="2juau.wam";
    const account=props.match.params.name;

    // const [contract,setContract]=useState(query.get("contract"));

  
    useEffect(() => {
  

      getTable(referContract,referContract,"accountg",account,account).then(r=>{
  
        if(r.rows.length>0)
        {
            setTotalReward(r.rows[0].t_reward);
            setTotalPlays(r.rows[0].t_plays);
            setGameNames(r.rows[0].games);
            setContractNames(r.rows[0].contracts);
        }
      }).catch(e=>{
        // alert(e);
      })

  
      return () => {
      }
    },[]);
  

    return (
      <div style={{width:'100%'}}>
      {isDesktopOrLaptop &&
      <div>
          <div style={styles.flex}>
              <div style={styles.leftCol}>
                  <div style={styles.flex}>
                      <Reward value={totalReward} />
                      <Spacer />
                      <GamesPlayed value={totalPlays} />
                  </div>
              </div>
              <div style={styles.rightCol}>
                  <div style={styles.flex}>
                      <RefGames
                          rg={gameNames}
                          contract={contractNames}
                          acc={account}/>
                  </div>
              </div>
          </div>
      </div>}

      {!isDesktopOrLaptop &&
      <div>
          <div style={styles.flexColumn}>
            <Reward value={totalReward} />
            <Spacer />
            <GamesPlayed value={totalPlays} />
            <Spacer />
            <RefGames
                rg={gameNames}
                contract={contractNames}
                acc={account}/>
          </div>
      </div>}

      

      </div>
  );
  };

  
export default React.memo(Account)