import * as React from 'react';
import { FC } from 'react';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import CardWithIcon from './CardWithIcon';


const GamesPlayed= ({ value }) => {
    return (
        <CardWithIcon
            // to=""
            icon={AllInclusiveIcon}
            title={'Referred Games played'}
            subtitle={value+''}
        />
    );
};

export default GamesPlayed;
