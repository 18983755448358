import * as React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import {
    ResponsiveContainer,
    LineChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';


const lastDay = new Date(new Date().toDateString()).getTime();
const oneDay = 24 * 60 * 60 * 1000;
const lastMonthDays = Array.from(
    { length: 30 },
    (_, i) => lastDay - i * oneDay
).reverse();

const aMonthAgo = new Date();
aMonthAgo.setDate(aMonthAgo.getDate() - 30);

const dateFormatter = (date) =>
    new Date(date).toLocaleDateString();

// const aggregateOrdersByDay = (orders: Order[]): { [key: number]: number } =>
//     orders
//         .filter((order: Order) => order.status !== 'cancelled')
//         .reduce(
//             (acc, curr) => {
//                 const day = new Date(
//                     new Date(curr.date).toDateString()
//                 ).getTime();
//                 if (!acc[day]) {
//                     acc[day] = 0;
//                 }
//                 acc[day] += curr.total;
//                 return acc;
//             },
//             {} as { [key: string]: number }
//         );

// const getRevenuePerDay = (orders: Order[]): TotalByDay[] => {
//     const daysWithRevenue = aggregateOrdersByDay(orders);
//     return lastMonthDays.map(date => ({
//         date,
//         total: daysWithRevenue[date] || 0,
//     }));
// };

const OrderChart = ({ dates }) => {
    const dd=1283185387000;
    const dd1=1273135219000;

    const data1 = dates;//[{key: 1596153514000, value: 400},{key: 1296173519000, value: 100}]
    
    // data.map(record => record.value.replace(" WAX",""));
    const cdate=new Date().getTime();

   if(data1.length==1) data1.push({key:(cdate)/1000,value:"0.000000 WAX"})
    let data = data1.map(obj => {
        let rObj = {}
        rObj['key'] = parseInt(obj.key)*1000;
        rObj['value']=parseFloat(obj.value.replace(" WAX","").replace("\"",""));
        return rObj
     })
     
    console.log(data);

    return (
        <Card>
            <CardHeader title={('Referral History')} />
            <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                <BarChart width={100} height={300} data={data}>
                <XAxis
                            dataKey="key"
                            name="Date"
                            type="number"
                            scale="time"
                            domain={[aMonthAgo.getTime(), new Date().getTime()]}
                            tickFormatter={dateFormatter}
                            // reversed
                        />
                        <YAxis dataKey="value"/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            // formatter={value =>
                            //     new Intl.NumberFormat(undefined, {
                            //         style: 'currency',
                            //         currency: 'WAX',
                            //     }).format(value)
                            // }
                            labelFormatter={(label) =>
                                dateFormatter(label)}
                                />
    
    <Bar dataKey="value" fill="#31708f" />
  </BarChart>

                    {/* <BarChart data={data} >

                        <XAxis
                            dataKey="key"
                            name="Date"
                            type="number"
                            scale="time"
                            // domain={[aMonthAgo.getTime(), new Date().getTime()]}
                            tickFormatter={dateFormatter}
                            reversed
                        />
                        <YAxis dataKey="value" name="Reward" unit="W" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            formatter={value =>
                                new Intl.NumberFormat(undefined, {
                                    style: 'currency',
                                    currency: 'WAX',
                                }).format(value)
                            }
                            labelFormatter={(label) =>
                                dateFormatter(label)}
                                />
                        <Bar dataKey="value" fill="#31708f" />
                    </BarChart> */}
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};


export default OrderChart;
