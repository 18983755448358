import React,{useEffect} from 'react'
import ReactSearchBox from 'react-search-box'
import {getTable} from './WaxHelper';
import Logo from './Logo';
import RefPic from './assets/refpic.jpg'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
    useHistory,
    useLocation
  } from "react-router-dom";
  
  import SearchIcon from '@material-ui/icons/Search';
  // import PageviewIcon from '@material-ui/icons/Pageview';
  const bg=require('./assets/refbg.png');
  const referContract="gamereferral";

function Splash() {
    const data = [{}];

      useEffect(() => {
        getTable(referContract,referContract,"accountg").then(r=>{
        var i=0;
        if(r.rows.length>0)
        {
            for(i=0;i<r.rows.length;i++)
                data.push({key:r.rows[i].account, value:r.rows[i].account});
        }
        }).catch(e=>{
        // alert(e);
        })

        return () => {}
      }, []);

      let history = useHistory();
      
        return (
            <div style={{width:'100%',height:'100%',opacity:'0.9',backgroundColor:'black'

        }}>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:"column",
                height: '200px',
                padding:'10px',
                opacity:1,
                backgroundPositionX:'center',
                backgroundRepeat:'no-repeat',
                backgroundSize:'contain', 
                backgroundImage: `url(${bg})`
            }}>
              <Logo/>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color:"purple",
                flexDirection:"row",
                fontFamily:"Courier",
                fontSize:'18sp',
                opacity:1,
            }}>
              <h3>The Chain of Referrers</h3>
              {/* <img src={RefPic} style={{width:'50px'}}/> */}
              {/* <SearchIcon/> */}
              </div>

            </div>

            <div style={{padding:'20px',display:'flex',flexDirection:'row',width:'100%', justifyContent: 'center',
}}>

<div style={{width:'70%'}}>
  
            <ReactSearchBox
            placeholder="Enter your WAX Account Name"
            value=""
            inputBoxHeight="60px"
            inputBoxFontSize="1.5em"
            data={data}
            autoFocus="true"
            onSelect={record => {
                console.log(record);
                history.push('/referrals/account/'+record.key);}}
          />

          </div>
          <SearchIcon style={{fontSize:'60px'}}/>

          </div>

          </div>
        )

}

export default Splash
