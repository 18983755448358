import * as React from 'react';
import { useMemo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import { Link } from 'react-router-dom';

import CardWithIcon from './CardWithIcon';
// import { Customer } from '../types';

const RefGames = ({rg,acc,contract}) => {

// console.log(myChain);

    // const nb = visitors ? visitors.reduce((nb: number) => ++nb, 0) : 0;
    return (
        <CardWithIcon
            // to=""                              
            icon={AccountTreeIcon}
            title={'Your Referred Games'}
            subtitle={rg.length}
        >
            <List>
                {rg
                    ? rg.map((record,index) => (
                          <ListItem
                              button
                              to={`/referrals/account/${acc}/contract/${contract[index]}`}
                              component={Link}
                              key={record}
                          >
                              <ListItemAvatar>
                                  <Avatar style={{background:'#b20000'}} >{index+1}</Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                  primary={record}
                                  secondary={contract[index]}
                                //   {`${record} ${
                                //       record.last_name
                                //   }`}
                              />
                          </ListItem>
                      ))
                    : null}
            </List>
        </CardWithIcon>
    );
};

export default React.memo(RefGames);
