import React from 'react'
import Pyramid from './Pyramid'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
        padding:'15px'
    },
}));



function About() {
  const classes = useStyles();

   return(
          <div>  
            <Card className={classes.root}>
                <CardHeader title="Multi-tiered Referral Architecture" />
            <Pyramid/>
            <h3 className={classes.cost}>
            We use the <b>multi-tiered architecture</b> to give rewards to both direct as well as indirect referrals. <br/>Consider chain: Alice refers to Bob, Bob refers Charlie <br/>In this case, if Charlie plays a game, Bob (direct referrer) receives reward (x%) as well as Alice receives (x% of the x%).<br/> For 100 WAX referral reward means, Bob receives 95 WAX and Alice receives 5 WAX.<br/>
          </h3>
            </Card>
        </div>
    )
}

export default About
