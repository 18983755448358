import React, { useState } from 'react';
import Aside from './Aside';
import Main from './Main';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import About from './About';
import Documentation from './Documentation';
import Account from './Account';
import { FaHeart } from 'react-icons/fa';
import Splash from './Splash';

function Layout() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <Router>
       <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
          <Aside
            image={image}
            collapsed={!isDesktopOrLaptop}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}/>

      <Switch>       
        <Route path="/" exact strict component={Splash}/>

        <Route path="/referrals" exact strict component={Splash}/>

        <Route path="/referrals/account/:name" exact strict component={Account}/>

        <Route path="/referrals/account/:name/contract/:contract" exact strict component={Main}/>

      <Route path="/referrals/about" exact strict component={About}/>

      <Route path="/referrals/documentation" exact strict component={Documentation}/>


    </Switch>
    </div>
    </Router>

    
  );
}

export default Layout;
