import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { Link } from 'react-router-dom';

import CardWithIcon from './CardWithIcon';
import StarRatingField from './ChainLength';

// interface Props {
//     reviews?: Review[];
//     customers?: { [key: string]: Customer };
//     nb?: number;
// }

const useStyles = makeStyles(theme => ({
    avatar: {
        background: '#b20000',
    },
    listItemText: {
        overflowY: 'hidden',
        height: '4em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
}));

const RefChains= ({  rc }) => {
    const classes = useStyles();
    // console.log(rc);

    return (
        <CardWithIcon
            // to=""
            icon={GroupWorkIcon}
            title={'Referral Chains you are part of'}
            subtitle={rc.length}
        >
            <List>
                {rc.map((record) => (
                    <ListItem
                        // key={record.id}
                        button
                        component={Link}
                        // to={``}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            {(
                            //     <Avatar
                            //         src={`${
                            //             customers[record.customer_id].avatar
                            //         }?size=32x32`}
                            //         className={classes.avatar}/>
                            // ) : (
                            <Avatar className={classes.avatar}>{record.value}</Avatar>
                            )}
                        </ListItemAvatar>

                        <ListItemText
                            primary={record.key}
                            secondary={<StarRatingField record={record.value} />}
                            className={classes.listItemText}
                            style={{ paddingRight: 0 }}/>
                    </ListItem>
                ))}
            </List>
        </CardWithIcon>
    );
};

export default React.memo(RefChains);
