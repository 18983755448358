import React from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
const ReactMarkdown = require('react-markdown/with-html')

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        maxHeight:'100%',
        overflowY:'scroll'
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
        padding:'15px'
    },
}));

const markdown = `
This is an open-source smart contract for managing the referrals and to pay the referral rewards directly to the users via the smart contract deployed on WAX Blockchain.

### Why is there a need and what is unique about this?

* <b>Enabling Gamification</b> – In any application or a game, the gamification methodology and functionalities need to be adapted to attract more users to the platform.

* <b>Transparent Rewards System</b> – The applications today use different kinds of centralized reward systems which are often not transparent. Using a blockchain-based reward system allows users to easily audit the rewards distribution.

* <b>Modular Design</b> – We know that each application is unique and have different business models or rewards management processes to grow their user-base. We designed the architecture to support different kinds of use-cases by adjusting the simple configuration parameters.

* <b>Smart Handling</b> – There are a lot of edge-case scenarios where the referral system might have problems with when using direct integrations into the blockchain smart contracts. We handle such edge-case scenarios autonomously. 

### What can you achieve with this?

* <b>Usecase-1: (Storing Referrals Data)</b> – Where User-A invites User-B with his/her referral link. The referral information can be stored in the blockchain tables as an account name.

* <b>Usecase-2: (Distributing Rewards for Direct Referrals)</b> – Let us assume DApp – X or a Marketplace – Z has promised the users with a 5% referral reward from the fees they collect. So, in this scenario, User-A can invite User-B and when user-B purchases the platform, 5% of the fee collected in that purchase will be paid out to User-A. The DApp – X or Marketplace – Z can also define how many purchases from User-B can User-A get rewards on.

* <b>Usecase-3: (Distributing Rewards for both Direct & In-Direct referrals)</b> – In the previous use case we saw a direct referral model but this can be extended to a multi-tiered model as to where all the referrers can be inter-linked, all the Users in the chain gets a % of reward defined by the DApp – X or Marketplace – Z.

The contract is currently deployed on:

<b>WAX mainnet</b> -> [gamereferral](https://wax.bloks.io/account/gamereferral?loadContract=true&tab=Tables&account=gamereferral&limit=100)              

### Architecture

In the current version of the smart contract, we support a configurable multi-tiered model to give rewards to both direct as well as indirect referrals.
Consider chain: Alice->Bob->Charlie (Alice refers Bob, Bob refers Charlie)
In this case, if Charlie plays a game, Bob (direct referrer) receives a reward (x%), as well as Alice, receives (x% of the remaining). For 100 WAX referral reward means, Bob receives 95 WAX and Charlie receives 5 WAX if the distribution % is set to 5%.
There can be no overdrawn balance, the x% is adjusted by the next immediate chain account only (in the above case by Bob).

### Get Started in 3 SIMPLE STEPS:

<b>1. Configure Game/App:</b> Complete the initialisation of your game/app dapp [here](https://wax.bloks.io/account/gamereferral?loadContract=true&tab=Actions&table=refer&account=gamereferral&limit=100&action=configuregame)              
    The action is callable by game account once or later to change game referral rules. Check below to know more about each field.

<b>2. Refer the user :</b>The action <i>refer</i> should be called once by referred account to stating the account name that referred from and the game account gameAcct. This shall be integrated in your dapp using waxjs. Sample block code is given at the end of this page.

<b>3. Transfer the fee :</b> The action <i> transfer </i> & <i> sendreward </i> should be called by the game account sending the game fee to be used for giving referral rewards. Memo shoud contain the game_id,account name to which referral reward is to be given. This shall be integrated in your game smart contract where you transfer the game fee to your account. 

#### <b>Integrate custom URL as per your game design to the automated user's referral page here</b>
<i>https://wax-essential.com/referrals/[ACCOUNT_NAME]/[CONTRACT_NAME]</i>


### Actions:

#### configuregame
<pre><code>ACTION configuregame (name contract, 
                      name fee_account,
                      string reward_per, 
                      uint8_t chain_length, 
                      uint8_t for_games, 
                      asset till_reward )
</code></pre>
* contract: account name of the game contract
* fee_account: the account where fee is collected in case referred account reaches threshold.
* reward_per: Reward percentage eg. 5.00% reward means each subsequent account gets 5% of 5% reward. Should be in format X.YZ format.
* chain_length: the length of the multi-tiered chain, must be <=10.
* for_games: the total no. of games for which account can gain referral reward.
* till_reward: the total max reward that the player account can gain.


#### referred
<pre><code>ACTION referred (name to, 
                 name from, 
                 name gameAcct)
</code></pre>

* from: the account name that refers
* to: the account that is referred.
* gameAcct: game account name 


#### transfer
<pre><code>ACTION transfer (name from,
                name to,
                asset quantity,
                string memo)
</code></pre>
<i>This is eosio.token's smart contract</i>
* from: any account name that wants to send tokens for use for game contract referrals
* to: should be 'gamereferral' for WAX-mainnet
* quantity: amount to be alloted for referral rewards
* memo: should be in format GAME_ID, CONTRACT_ACCOUNT. Note this should be sent in correct format.


#### sendreward
<pre><code>ACTION sendreward (name from,
                    asset quantity,
                    uint64_t g_id,
                    name player_acc)
</code></pre>
<i>You can call this action from within smart contract also</i>
* from: game contract account
* quantity: amount to be alloted for referral rewards for particular user
* g_id: game_id of particular game for which referral is issued
* player_acc: account name of player


### Tables

#### gameacc
<pre><code>TABLE gameacc {
    name contract;
    name fee_account;
    string reward_per;
    uint8_t chain_length;
    uint8_t for_games;
    asset till_reward;
};
</code></pre>

Scope is _self.

\n\n

#### accountg
<pre><code>TABLE accountg {
    name account;
    vector<name> games;
    vector<name> contracts;
    asset t_rewards;
    uint64_t t_plays;
};
</code></pre>

Scope is _self.

#### balance
<pre><code>TABLE balance {
    name account;
    asset bal;
};
</code></pre>

Scope is _self.

#### refer
<pre><code>TABLE referacc {
    name account;
    vector<name> refer_chain;
    uint8_t r_games;
    asset r_reward;
    map (name,uint8_t) me_chains;
    map (uint32_t,asset) dates;
};
</code></pre>

Scope is your game account name.
  

### Sample code block for referring using waxjs:
<pre><code>const transactRefer=((to,from,wax)=>{
    const actionName = "referred";
    const actionData = {
      to:to,
      from:from,
      gameAcct:smartcontractacct
    };
    try {
      const result =  wax.transaction(actionName, actionData,'gamereferral').then((r=>{
          console.log(r+"Referral on blockchain successful applied from:"+from);
      })
      ,(e=>{
          console.log("Caught exception in transaction: " + e);
        }));
    } catch (e) {
        console.log("Caught exception: " + e);
    }
})
</code></pre>
For more info on waxjs, check here https://github.com/worldwide-asset-exchange/waxjs
`

function Documentation() {
    const classes = useStyles();
    return (
    <div>
        <Card className={classes.root}>
            <CardHeader title="Developer Documentation" />
            <div className={classes.cost}>
                <ReactMarkdown 
                    source={markdown}
                    escapeHtml={false}
                />
            </div>
        </Card>
    </div>
)

}

export default Documentation