import * as waxjs from "@waxio/waxjs/dist";

export const getTable= async (scope, contractName, tableName, keyValue,upperkeyValue, limit,secondaryIndex) => {
    const wax = new waxjs.WaxJS('https://api.wax.greeneosio.com', null, null, false);

    try {
        const tableQuery = {
            json: true,
            code: contractName,
            scope: scope,
            table: tableName,
            index_position: secondaryIndex?secondaryIndex:1,
            key_type: 'i64',
            lower_bound: keyValue? keyValue : '',
            upper_bound: upperkeyValue? upperkeyValue : '',
            limit: limit ? limit : 100,
            reverse: false,            // Optional: Get reversed data
            show_payer: false,         // Optional: Show ram payer
        };

        const data = await wax.rpc.get_table_rows(tableQuery);

        return data;
    } catch (err) {
    }
};